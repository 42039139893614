<template>
  <div>
    <nav id="navbar" runat="server" class="fixed-bottom shadow nav-fill border-top" v-if="user.user_type === 'checkin' || user.user_type === 'service'">
      <ul class="nav">
        <router-link :to="{ name: 'Inout', query: { id: $route.query.id }}" class="nav-item py-2">
          <i class="fal fa-home"></i>
          <div class="text-opcio">{{ translator.inici }}</div>
        </router-link>

        <!-- Arrivals para innout -->
        <router-link v-if="user.user_type !== 'service'" :to="{ name: 'Arrivals', query: { id: $route.query.id }}" class="nav-item py-2">
          <i class="fal fa-sign-in"></i>
          <div class="text-opcio">{{ translator.arribades }}</div>
        </router-link>

        <!-- Departures para innout -->
        <router-link v-if="user.user_type !== 'service'" :to="{ name: 'Departures', query: { id: $route.query.id }}" class="nav-item py-2">
          <i class="fal fa-sign-out"></i>
          <div class="text-opcio">{{ translator.sortides }}</div>
        </router-link>

        <!-- Departures para innout -->
        <router-link v-if="user.user_type !== 'service'" :to="{ name: 'Calendar', query: { id: $route.query.id }}" class="nav-item py-2">
          <i class="fal fa-calendar-alt"></i>
          <div class="text-opcio">{{ translator.calendari }}</div>
        </router-link>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'NavbarCheckin',
  props: ['user', 'translator'],
}
</script>

<style scoped lang="scss">
#navbar {

  a {
    color: var(--colores-gris-medio);

    &.router-link-exact-active {
      color: var(--azul-oscuro);
    }
  }
}
</style>