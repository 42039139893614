<template>
    <div class="pc inout">
        <div v-if="isPreLoad" class="preload">
            <div class="preload-wrapper"></div>
            <div class="preload-dots">
                <div class="hollow-dots-spinner">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
            </div>  
        </div>

        <header v-if="translator" class="p-3">
            <div class="row align-items-center">
                <div class="col-6">
                    <h1 v-cloak>{{ translator.activitat }}</h1>
                </div>
                <div class="col-6 text-right">
                    <!-- botón para profile aquí -->
                </div>
            </div>
        </header>

        <div v-if="dashboard" class="container p-4">
            <p v-cloak>{{ translator.informesdiaris }}</p>

            <router-link :to="{ name: 'Arrivals', query: {id: String(this.$route.query.id).replace(/[ ]/g, '+')} }">
                <div class="card card-llegadas mb-3">
                    <div class="card-body shadow-sm">
                        <h5 class="card-title">
                            <span v-cloak>{{ translator.arribades }}</span>
                        </h5>
                        <div class="row">
                            <div class="col-4" v-cloak>
                                <label>
                                    <span v-cloak>{{ translator.avui }}</span>
                                </label>
                                <span class="num-avui">{{ dashboard.arrivals_today }}</span>
                            </div>
                            <div class="col-6" v-cloak>
                                <label>
                                    <span v-cloak>{{ translator.aquestasetmana }}</span>
                                </label>
                                <span class="num-aquestasetmana">{{ dashboard.arrivals_week }}</span>
                            </div>
                            <div class="col-2">
                                <i class="fal fa-sign-in fa-lg"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </router-link>

            <router-link :to="{ name: 'Departures', query: {id: String(this.$route.query.id).replace(/[ ]/g, '+')} }">
                <div class="card card-salidas mb-3">
                    <div class="card-body shadow-sm">
                        <h5 class="card-title">
                            <span v-cloak>{{ translator.sortides }}</span>
                        </h5>
                        <div class="row">
                            <div class="col-4" v-cloak>
                                <label>
                                    <span v-cloak>{{ translator.avui }}</span>
                                </label>
                                <span class="num-avui">{{ dashboard.departures_today }}</span>
                            </div>
                            <div class="col-6" v-cloak>
                                <label>
                                    <span v-cloak>{{ translator.aquestasetmana }}</span>
                                </label>
                                <span class="num-aquestasetmana">{{ dashboard.departures_week }}</span>
                            </div>
                            <div class="col-2">
                                <i class="fal fa-sign-out fa-lg"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </router-link>
        </div>

        <NavbarCheckin :translator="translator" :user="user" />
    </div>
</template>

<script>
import NavbarCheckin from '@/components/NavbarCheckin.vue';
import { mapState } from "vuex";

export default {
    name: 'Inout',
    components: {
        NavbarCheckin
    },
    computed: {
        ...mapState("utils", ["user", "translator"]),
        ...mapState("checkin", ["dashboard"]),
    },
    watch: { 
      translator() {
        this.isPreLoad = false;
      }
     },
    async created() {
        this.isPreLoad = true;
        try {
            await this.$store.dispatch("utils/getUser", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
            await this.$store.dispatch("checkin/getDashboard", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
        } catch (error) {
            console.error(error);
        } finally {
            //this.isPreLoad = false;
        }
    },
    data() {
        return {
            isPreLoad: false,
        };
    },
    methods: { }
}
</script>

<style lang="scss" scoped>
</style>